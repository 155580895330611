<template>
  <div>
    <appr-main :isShow="isShow"></appr-main>
  </div>
</template>

<script>
import apprMain from "@/views/component/apprCommon/apprMain";
export default {
  name: "CrftEditAppr",
  components: {
    apprMain
  },
  props:{
    isShow:{
      type:Boolean,
      required:true,
    }
  },
}
</script>

<style scoped>

</style>